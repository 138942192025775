import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/HR1.jpg"

export default function Hr(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>HUMAN RESOURCE DEPARTMENT</h1>
       
            <div style={{ display:"flex",  marginTop:"50px", justifyContent:'center', color:"#008069", alignSelf:'center'}}>
            </div>
            <div >
            <img src={pic} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", border:"3px solid #008069", margin: "auto" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>MR SUUGYAA N-DUOSILA</h3>
            <h4 style={{textAlign:"center"}}><b>HUMAN RESOURCE MANAGER</b></h4>
            <div style={{marginLeft:"70px", marginRight:"70px", fontSize:"25px", marginBottom: "50px"}}>
            <p>The Human Resource Department coordinates all human resources programmes; and organizes staff trainings within the Assembly.</p>
            <p>The department ensures the effective and efficient administration of human resources; translate institutional policies in respect of employment, personnel, wages and salaries into good management practices. The department also updates the records of staff on regular basis as well as ensuring the inter and intra departmental collaboration to facilitate staff performance and development.</p>
            <p>The Human Resource Department reports on all human resource related activities and put in measures to upgrade the capabilities, skills and knowledge of staffs in the Assembly. The department also performs other roles such as seeing to the general welfare of Staffs and Appraising direct reports.</p>

            <p> The following are the duties of the Human Resource Department;</p>
           <ol>
               <li>Recruitment, selection and retention.</li>
               <li>Learning, training and development.</li>
               <li>Promotions, posting and transfers</li>
               <li>Discipline and grievance procedures.</li>
               <li>Handling lateness and absence</li>
               <li>Performance management process</li>
               <li>Compensation and Benefits for employees</li>
               <li> Health, safety and environment</li>
               <li>Leave</li>
               <li>Ethics, values and code of conduct</li>
            </ol>
            </div>
            </div>
            </div>
        <Footer/>
        </>
    )
}