import React from "react"; import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import chiefs from "../Images/CHIEFS.png";
import OSCAR11 from "../Images/OSCAR11.jpg";
import main from "../Images/MAIN.png";
import STAFF1 from "../Images/STAFF1.JPG";
import LITTLEGIRL from "../Images/LITTLEGIRL.JPG";
import NANA1 from "../Images/NANA1.JPG";
import NANA5 from "../Images/NANA5.JPG";
import NANA3 from "../Images/NANA3.JPG";
import FARM4 from "../Images/FARM4.JPG";
import FARM5 from "../Images/FARM5.JPG";
import AGRIC1 from "../Images/AGRIC1.jpg"

 
const AutoplaySlider = withAutoplay(AwesomeSlider);
 
const slider = (
  <AutoplaySlider 
    play={true}
    cancelOnInteraction={false} // should stop playing on user interaction
    interval={4000}
  >
    <div data-src={chiefs} />
    <div data-src={OSCAR11}  />
    <div data-src={main} />
    <div data-src={STAFF1}  />
    <div data-src={LITTLEGIRL}  />
    <div data-src={NANA1}  />
    <div data-src={NANA5}  />
    <div data-src={NANA3}  />
    <div data-src={FARM4}  />
    <div data-src={FARM5}  />
    <div data-src={AGRIC1}  />
  </AutoplaySlider>
);
const Main=()=>{ 
    return(
        <div style={{marginBottom:"50px"}}>
            {slider}
        </div>
    )
}
export default Main