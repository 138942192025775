import React, { useEffect, useState } from 'react'
import {Box,Row, Column, FooterLink, Heading, Container} from "./FooterStyle"
import {BatteryCharging20, Call, Email, Facebook, FaceRounded, GpsFixedRounded, GpsFixedTwoTone, Home, Instagram, LinkedIn, LocalActivity, LocalActivitySharp, LocalGasStation, LocalPostOffice, LocationCity, LocationCityOutlined, LocationOn, LocationSearching, Twitter, X, YouTube} from "@material-ui/icons"
import { faFacebook, faInstagram, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./Footer.css"
export default function FooterComponent() {
    const[uiWidthState, setUiWidthState] = useState(true)

    useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)

            if(window.innerWidth>960){
                setUiWidthState(true)
            }else{
                setUiWidthState(false)
            }
        }

        window.addEventListener('resize', handleResize)
    }, [])

    return (
        <Box style={{backgroundColor:"#008069", paddingLeft:"20px", paddingRight:"20px"}}>
                <Container style={{justifyContent:"JUSTIFY", alignItems:"JUSTIFY", marginLeft:"120px", marginRight:"0px", backgroundColor:"#008069"}}>
                        <Row style={{display:"flex", flexDirection:"row"}}>
                            <div style={{flex:1.6, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"10px", textDecoration:"underline", textAlign:"left", color:"gold", marginBottom:"2px"}}>ABOUT US</Heading>
                                <FooterLink href="/home">Home</FooterLink>
                                <FooterLink href="/profile">Profile</FooterLink>
                                <FooterLink href="/mission">Mission</FooterLink>
                                <FooterLink href="/mission">Vision</FooterLink>
                            </div>
                            <div style={{flex:3.5, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"10px", textDecoration:"underline", textAlign:"left", color:"gold", marginBottom:"2px"}}>OUR CONTACTS</Heading>
                                <FooterLink href="/contact"><Home style={{marginRight: "10px"}}/> P. O. Box 1, Manso-Nkwanta</FooterLink>
                                <FooterLink href="/contact"><Email style={{marginRight: "10px"}}/> info@amansiwestda.gov.gh</FooterLink>
                                <FooterLink href="/contact"><Call style={{marginRight: "10px"}}/>+233(0)322-490947</FooterLink>
                                <FooterLink href="/contact"><LocationOn style={{marginRight: "10px"}}/>AW-0001-7236</FooterLink>
                            </div>
                            <div style={{flex:3, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"10px", textDecoration:"underline", textAlign:"left", color:"gold", marginBottom:"2px"}}>OUR SERVICES</Heading>
                                <FooterLink href="/businessOperatingPermit">Business Operating Permit</FooterLink>
                                <FooterLink href="/buildingPermit">Building Permit</FooterLink>
                                <FooterLink href="/marriageRegistration">Marriage Registration</FooterLink>
                            </div> 
                            <div style={{flex:4, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"10px", textDecoration:"underline", textAlign:"left", color:"gold", marginBottom:"2px"}}> OTHER LINKS</Heading>
                                <FooterLink href="https://lgs.gov.gh">Office of Head of Local Gov't Service</FooterLink>
                                <FooterLink href="http://www.mlgrd.gov.gh">Ministry of Local Government & Rural Dev't</FooterLink>
                                <FooterLink href="https://mofep.gov.gh">Ministry of Finance</FooterLink>
                                <FooterLink href="https://www.ghaudit.org">Audit Service</FooterLink>
                            </div>
                        </Row >
                        <div style={{flex:0.8, display:"flex", flexDirection:"column"}}>
                            <Heading style={{marginTop:"2px", textDecoration:"underline", textAlign:"center", color:"gold", marginRight: "5px", marginBottom:"2px"}}>SUBSCRIBE NOW</Heading>
                            <div style={{ alignItems:"flex-end", fontSize:"50px", display: "grid", flexDirection:"row", gridTemplateColumns:"repeat(4, auto)", gridGap:"50px", marginBottom:"20px", marginTop:"10px"}}>
                                <a href="https://www.facebook.com/AmansieWestDA" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} size="1.5x" style={{ backgroundColor:"", color:"#003297", marginRight: "7.5px"}}/>
                                </a>
                                <a href="https://www.instagram.com/amansiewest" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="1.5x" style={{ backgroundColor:"", color:"#F56040", marginRight: "7.5px"}}></FontAwesomeIcon>

                                </a>
                                <a href="https://twitter.com/AmansieWest" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faXTwitter} size="1.5x" style={{ backgroundColor:"", color:"BLACK", marginRight: "7.5px"}}/>
                                </a>
                                <a href="https://www.youtube.com/channel/UCS47IIhxUYMv8ITjxfKRGkQ" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutube} size="1.5x" style={{ backgroundColor:"", color:"#FF0000", marginRight: "7.5px"}}/>
                                </a>
                            </div>
                            </div>



                <div style={{textAlign: "justify", marginTop: "10px", marginBottom: "20px"}}>
                    <a style={{textDecoration: "none", color: "white"}} class='website-rights'>
                    <strong style={{fontSize:"20px", marginBottom:"10px", textAlign:"justify"}}>Copyright 2018 - 2025. AMANSIE WEST DISTRICT ASSEMBLY. All rights reserved || Designed by the MIS Unit +233 20-336-5586</strong>
                    </a>
                </div>
            </Container>
        </Box>
    )
}