import React from "react";

const Pic = (props) => {
  return (
    <div className="backdrop">
      <img src={props.src} alt="selectedpic" />
    </div>
  );
};

export default Pic;

