import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import MAP1 from '../Images/MAP1.PNG'
import { FooterLink, Heading} from "../Resources/FooterStyle"
import {BatteryCharging20, Call, Email, Facebook, FaceRounded, GpsFixedRounded, GpsFixedTwoTone, Home, Instagram, LinkedIn, LocalActivity, LocalActivitySharp, LocalGasStation, LocalPostOffice, LocationCity, LocationCityOutlined, LocationOn, LocationSearching, Twitter, YouTube} from "@material-ui/icons"


export default function ContactsUs(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div className="container" style={{background:"white"}}>
        <h1 style={{textAlign:"center", color:"#008069"}}>OUR CONTACTS</h1>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <img src={MAP1} style={{ width: "100%",borderRadius:"10px", border:"5px solid #008069", alignItems:"center", margin:"40px"}}></img>
        </div>
        <div style={{flex:1.4, display:"flex", flexDirection:"column", fontSize:"18px", marginLeft:"50px" }}>
            <h2 style={{marginBottom: "20px", marginTop: "20px", textAlign: "justify", color:" #008069"}}>You can contact the Office of the Amansie West District Assembly through the following medium.</h2>
            <h2><Home style={{marginRight: "10px",marginBottom: "20px", color:"#008069"}}/>   P. O. Box 1, Manso-Nkwanta</h2>
            <h2><Email style={{marginRight: "10px",  marginBottom: "20px", color:"GOLD"}}/>       info@amansiwestda.gov.gh</h2>
            <h2><Call style={{marginRight: "10px", marginBottom: "20px", color:"blue"}}/>      +233(0)322-490947</h2>
            <h2><LocationOn style={{marginRight: "10px", marginBottom: "20px", color:"red"}}/> AW-0001-6822</h2>
        </div>
        </div>
        <Footer/>
        </>
    )
}
