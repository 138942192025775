import logo from './logo.svg';
import './App.css';
import Home from './Components/Pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Profile from './Components/Pages/Profile';
import Services from './Components/Pages/Services';
import ContactUs from './Components/Pages/ContactUs';
import News from './Components/Pages/News';
import Assembly from "./Components/Pages/Assembly";
import Gallery from './Components/Pages/Gallery';
import Ongoing from './Components/Pages/Ongoing';
import Documents from './Components/Pages/Documents';
import Mission from './Components/Pages/Mission';
import MarriageRegistration from './Components/Pages/MarriageRegistration';
import BuildingPermit from './Components/Pages/BuildingPermit';
import Completed from './Components/Pages/Completed';
import AssemblyMembers from './Components/Pages/AssemblyMembers';
import Business from './Components/Pages/Business';
import Agric from './Components/Pages/Agric';
import Central_Administration from './Components/Pages/Central_Administration';
import Education from './Components/Pages/Education';
import Finance from './Components/Pages/Finance';
import Health from './Components/Pages/Health';
import Nadmo from './Components/Pages/Nadmo';
import Physical from './Components/Pages/Physical';
import Social from './Components/Pages/Social';
import Works from './Components/Pages/Works';
import AssemblyHome from './Components/Pages/AssemblyHome';
import ServicesHome from './Components/Pages/ServicesHome';
import NewsHome from './Components/Pages/NewsHome';
import Hr from './Components/Pages/HR';
import Statistics from './Components/Pages/Statistics';
import Departments from "./Components/Pages/Departments";
import UploadNews from './Components/Pages/UploadNews';
import NewsSelected from './Components/Pages/NewsSelected';
import UploadDoc from './Components/Pages/UploadDoc';
import Programmes from "./Components/Pages/Programmes";

function App() {
  return (
   <div>
     <Router>
       <Routes>
          <Route path="/" exact element={<Home/>}/> 
          <Route path="/profile" exact element={<Profile/>}/>
          <Route path="/services" exact element={<Services/>}/>
          <Route path="/contact" exact element={<ContactUs/>}/>
          <Route path="/news" exact element={<News/>}/>
          <Route path="/businessOperatingPermit" exact element={<Business/>}/>
          <Route path="/assembly" exact element={<Assembly/>}/>
          <Route path="/contact" exact element={<ContactUs/>}/>
          <Route path="/gallery" exact element={<Gallery/>}/>
          <Route path="/ongoing" exact element={<Ongoing/>}/>
          <Route path="/departments" exact element={<Departments/>}/>
          <Route path="/documents" exact element={<Documents/>}/>
          <Route path="/mission" exact element={<Mission/>}/>
          <Route path="/services" exact element={<Services/>}/>
          <Route path="/marriageRegistration" exact element={<MarriageRegistration/>}/>
          <Route path="/buildingPermit" exact element={<BuildingPermit/>}/>
          <Route path="/completed" exact element={<Completed/>}/>
          <Route path="/assembly" exact element={<Assembly/>}/>
          <Route path="/assemblyMembers" exact element={<AssemblyMembers/>}/>
          <Route path="/agric" exact element={<Agric/>}/>
          <Route path="/central" exact element={<Central_Administration/>}/>
          <Route path="/education" exact element={<Education/>}/>
          <Route path="/finance" exact element={<Finance/>}/>
          <Route path="/health" exact element={<Health/>}/>
          <Route path="/nadmo" exact element={<Nadmo/>}/>
          <Route path="/physical" exact element={<Physical/>}/>
          <Route path="/social" exact element={<Social/>}/>
          <Route path="/works" exact element={<Works/>}/>
          <Route path="/assemblyHome" exact element={<AssemblyHome/>}/>
          <Route path="/servicesHome" exact element={<ServicesHome/>}/>
          <Route path="/newsHome" exact element={<NewsHome/>}/>
          <Route path="/hr" exact element={<Hr/>}/>
          <Route path="/statistics" exact element={<Statistics/>}/>
          <Route path="/upload_news" exact element={<UploadNews/>}/>
          <Route path="/news_selected" exact element={<NewsSelected/>}/>
          <Route path="/upload_docs" exact element={<UploadDoc/>}/>
          <Route path="/programmes" exact element={<Programmes/>}/>
        </Routes>
     </Router>
   </div>
  );
}

export default App;
