import React from "react";
import pic from "../Images/REGMAP.jpg"
import Banner from "../Resources/Banner";
import Navbar from "../Resources/Navbar";
import Footer from "../Resources/Footer";

const NewsSelected=()=>{
    return (
        <div>
            
            <Banner/>
            <Navbar/>
            <div className="container" style={{background:"white", textAlign:"center", marginTop:"20px", marginBottom:"20px", padding:"20px"}}>
                <div style={{display:"flex", flexDirection:"row"}}>
            <div style={{width:"100%"}}>
            <h1 style={{ display:"flex", textAlign:"center", marginTop:"20px", color:"#008069", justifyContent:'center',  alignSelf:'center'}}>{localStorage.getItem("news_topic")}</h1>
                {localStorage.getItem("news_image")!=="No Image"?<div style={{ display:"flex",  marginTop:"0px", justifyContent:'justify', color:"#008069", alignSelf:''}}>
            <img src={localStorage.getItem("news_image")} style={{height:"500px",width:"70%",  display:"block", justifyContent:"center", alignItems:"center", borderRadius:"5px", margin: "auto"}}/>
            </div>:null}

            <div className="justify-text" style={{ textAlign:"left", margin:'10px', fontSize: "20px" }}>
                <p>{localStorage.getItem("news_text")}</p>
            </div>

            {/*<div ClassName="card" style={{marginLeft:"100px", marginRight:"100px"}}>
                <p ClassName="container">
		<h2 align="justify" style={{ color:"#008069", marginBottom:"2px", marginTop:"10px"}}></h2>
            <div style={{marginLeft:"50px", fontSize:"20px"}}>
        	<p>{localStorage.getItem("news_text")}</p>
		</div>
                </p>
        </div>
        */}
        </div>
        {/* { <div style={{width:"30%", backgroundColor:"#fff"}}>
 <h2>Upcoming Events</h2>
        </div> } */}
        </div>
        </div>
            <Footer/>
        </div>
    )
}
export default NewsSelected