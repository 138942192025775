import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/BUSINESS.jpg"
import ServiceItem from "../Resources/ServiceItem"

export default function Business(){
    return(
        <>
        <Banner/>
        <Navbar/>
        <div style={{marginLeft:"50px"}}>
        <div className="container" style={{background:"white"}}>
        <ServiceItem heading="BUSINESS OPERATING PERMIT" text="" src={pic}/>
        </div>
        <h1 style={{fontWeight:"bold", textAlign:"center",textDecoration:"underline", color:"#008069"}}>PROCESS TO ACQUIRE BUSINESS OPERATING PERMIT</h1>
        
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}> Requirements</h3> 
            <p>The prospective Business Owner must have the following:</p>
                <ol typeof="1">
                    <li>Ghana Card</li>
                    <li>Tax Identification Number(TIN)</li>
                    <li>Certificate from Registrar of Companies(If Any).</li>
                    <li>Application letter.</li>
                    </ol>
        
        
        <h2 style={{fontWeight:"bold", textDecoration:"underline", color:"#008069"}}>HOW TO APPLY</h2>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>STEP 1:</h3>
        <p >Any person who intends to operate business within the District must write an application letter to the District Chief Executive</p>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>STEP 2:</h3>
        <p>Inspection of Business Environment. <b>The Environment is inpected by the relevant authority</b></p>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>STEP 3:</h3>
        <p>Purcahse of Business Registration Form </p>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>STEP 4:</h3>
        <p>Payment of Fees. <b>The fee will be determined by the FEE FIXING</b></p>
        <h3 style={{fontWeight:"bold",  textDecoration:"underline", color:"#008069"}}>STEP 5:</h3>
        <p>Issuance of Certificate. <b>The cirtificate will be issued in 7 working days</b></p>
        </div>
        <Footer/>
        </>
    )
}


