import { Add, ArrowDownward, ArrowDownwardOutlined, ArrowDownwardRounded, ArrowDownwardSharp, ArrowDropDownCircle, ArrowDropDownCircleOutlined, Call, CallEndRounded, CallMade, Close, ContactSupport, Facebook, Home, KeyboardArrowDownRounded, LinkedIn, MenuOpen, MenuOutlined, Phone, Remove, Twitter, WhatsApp } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import "./ButtonDrop.css"
// import FontAwesome from 'react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

import './navbar.css'


function Navbar(props){
    let navigate = useNavigate();
const [click, setClick] = useState(false)
const [button, setButton] = useState(true)

const closeMobileMenu = ()=>setClick(false)
const showButton = ()=>{
    if(window.innerWidth<=960){
        setButton(true)
       
    }else{
        setButton(false)
       if(click){
        setClick(false)
       }
    }
}
useEffect(()=>{
    showButton()
}, [])

window.addEventListener('resize', showButton);

const [menuState, setMenuState] = useState(false)
const [aboutState, setAboutState] = useState(false)
const [mediaState, setMediaState] = useState(false)
const [projectState, setProjectState] = useState(false)
const [deptState, setDeptState] = useState(false)
const [servState, setServState] = useState(false)


  
return(
  <>
      <nav className="navbar" style={{backgroundColor:'#008069'}}>
          <div className="navbar-container">
          <Link to="/" className="navbar-logo">
      AMANSIE WEST
              </Link>
              <div className="menu-icon"  onClick={()=>{setClick(!click)}}>
                  {click ?<Close style={{color:'white'}}/>:<MenuOutlined style={{color:'white'}}/>}
                  
                  {/* { <FontAwesome
                      className='super-crazy-colors'
                      name={click?'times':'bars'}
                  
                     // spin
                      style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                  /> } */}
                  
                  </div>
                  <ul >
                      {!button?
                  <div className={click ?'nav-menu active' : 'nav-menu'}>
                  <li className='nav-item'>
                      <div className="dropdown">
                      <button onClick={()=>{navTo("/")}} className="dropbtn"><Home style={{marginRight:"10px"}}/>Home</button>
                          </div>
                      </li>
                      <li className='nav-item'>
                      <div className="dropdown">
                      <button className="dropbtn">About us<KeyboardArrowDownRounded style={{marginLeft: "2px"}}/></button>
                      <div className="dropdown-content">
                          <a href="/profile">Profile</a>
                          <a href="/mission">Mission&Vision</a>
                          {/* <a href="/assembly">The Assembly</a> 
                          <a href="assemblyMembers">Assembly Members</a>*/}
                      </div>
                      </div>
                      </li>
                      <li className='nav-item'>
                      <div className="dropdown">
                      <button className="dropbtn">Media<KeyboardArrowDownRounded style={{marginLeft: "5px"}}/></button>
                      <div className="dropdown-content">
                          <a href="/news">News</a>
                          <a href="/gallery">Gallery</a>
                          <a href="/documents">Documments</a>
                      </div>
                      </div>
                      </li>
                      <li className='nav-item'>
                      <div className="dropdown">
                      <button className="dropbtn">Departments<KeyboardArrowDownRounded style={{marginLeft: "5px"}}/></button>
                      <div className="dropdown-content">
                          <a href="/agric">Agric</a>
                          <a href="/central">Central Administration</a>
                          <a href="/education">Education</a>
                          <a href="/finance">Finance</a>
                          {/* <a href="/health">Health</a> */}
                          <a href="/departments">View All</a>
                          {/* <a href="/information">Works</a>
                          <a href="/nadmo">NADMO</a>
                          <a href="/physical">Physical Planning</a>
                          <a href="/social">Social Welfare & Community Dev't</a>
                          <a href="/works">Works</a> */}
                      </div>
                      </div>
                      </li>
                      <li className='nav-item'>
                      {/*<div className="dropdown">
                      <button className="dropbtn">Projects<KeyboardArrowDownRounded style={{marginLeft: "5px"}}/></button>
                      <div className="dropdown-content">
                          <a href="/ongoing">On-going</a>
                          <a href="/completed">Completed</a>
                      </div>
                      </div>*/}
                      </li>
                      <li className='nav-item'>
                      <div className="dropdown">
                      <button className="dropbtn">Services<KeyboardArrowDownRounded style={{marginLeft: "5px"}}/></button>
                      <div className="dropdown-content">
                          <a href="/buildingPermit">Building Permit</a>
                          <a href="businessOperatingPermit">Business Operating Permit</a>
                          <a href="/marriageRegistration">Marriage Registration</a>
                      </div>
                      </div>
                      </li>

                      <li className='nav-item'>
                      <div className="dropdown">
                      <button onClick={()=>{navTo("/contact")}} className="dropbtn" ><Call style={{marginRight:"5px"}}/>ContactUs</button>
                      
                          </div>
                      </li>
                  </div>
                    :
// mobile view
                    <div className={click ?'nav-menu active' : 'nav-menu'}>
                  <li className='nav-item'>
                      <a href='/' className='dropdownsmall'>
                      <button className="dropbtn"><Home style={{marginRight:"10px"}}/>Home</button>
                          </a>
                      </li>
                      <li className='nav-item'>
                      <div className="dropdownsmall">
                      <button className="dropbtn" onClick={()=>{setAboutState(!aboutState)}}>About us<KeyboardArrowDownRounded style={{marginLeft: "2px"}}/></button>
                      </div>
                      </li>
                      {aboutState?<div style={{display:'flex', flexDirection:'column', marginLeft:'10px'}} >
                      <a className='dropdownsmall'  href="/profile">
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Profile</button>
                      </a>
                      <a href="/mission" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Mission&Vision</button>
                      </a>
                      <a href="/assembly" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>The Assembly</button>
                      </a>
                      <a href="/assemblyMembers" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Assembly Members</button>
                      </a>
                         
                      </div> :<div/>}

                      <li className='nav-item'>
                      <div className="dropdownsmall">
                      <button className="dropbtn" onClick={()=>{setMediaState(!mediaState)}}>Media<KeyboardArrowDownRounded style={{marginLeft: "2px"}}/></button>
                      </div>
                      </li>
                      {mediaState?<div style={{display:'flex', flexDirection:'column', marginLeft:'10px'}} >
                      <a className='dropdownsmall'  href="/news">
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>News</button>
                      </a>
                      <a href="/gallery" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Gallery&Vision</button>
                      </a>
                      <a href="/documents" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Documents</button>
                      </a>
                         
                      </div> :<div/>}


                      <li className='nav-item'>
                      <div className="dropdownsmall">
                      <button className="dropbtn" onClick={()=>{setDeptState(!deptState)}}>Departments<KeyboardArrowDownRounded style={{marginLeft: "2px"}}/></button>
                      </div>
                      </li>
                      {deptState?<div style={{display:'flex', flexDirection:'column', marginLeft:'10px'}} >
                      <a className='dropdownsmall'  href="/agric">
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Agric</button>
                      </a>
                      <a href="/central" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Central Administration</button>
                      </a>
                      <a href="/education" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Education</button>
                      </a>
                      <a href="/finance" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Finance</button>
                      </a>

                      <a href="/departments" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>View All</button>
                      </a>
                         
                      </div> :null}


                      <li className='nav-item'>
                      <div className="dropdownsmall">
                      <button className="dropbtn" onClick={()=>{setProjectState(!projectState)}}>Projects<KeyboardArrowDownRounded style={{marginLeft: "2px"}}/></button>
                      </div>
                      </li>
                      {projectState?<div style={{display:'flex', flexDirection:'column', marginLeft:'10px'}} >
                      <a className='dropdownsmall'  href="/ongoing">
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>On-going</button>
                      </a>
                      <a href="/completed" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Completed</button>
                      </a>
                         
                      </div> :null}


                     
                      <li className='nav-item'>
                      <div className="dropdownsmall">
                      <button className="dropbtn" onClick={()=>{setServState(!servState)}}>Services<KeyboardArrowDownRounded style={{marginLeft: "2px"}}/></button>
                      </div>
                      </li>
                      {servState?<div style={{display:'flex', flexDirection:'column', marginLeft:'10px'}} >
                      <a className='dropdownsmall'  href="/buildingPermit">
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Building Permit</button>
                      </a>
                     
                      <a href="/businessOperatingPermit" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Business Operating Permit</button>
                      </a>
                      <a href="/marriageRegistration" className='dropdownsmall'>
                         <button className="dropbtn"><Remove style={{marginRight: "2px"}}/>Marriage Registration</button>
                      </a>
                         
                      </div> :null}
                   

                      <li className='nav-item'>
                      <a href='/contact' className="dropdownsmall">
                      <button className="dropbtn" ><Call style={{marginRight:"5px"}}/>ContactUs</button>
                      
                          </a>
                      </li>
                  </div>
                    }
                      {/* <li className='nav-item'>
                      <a href='#' className='nav-links' onClick={closeMobileMenu}>
                          <Twitter/>
                          </a>
                      </li> */}
                      
                      

                      
                  </ul>
                  {/* {button && <Button
                  buttonStyle='btn--outline'
                  path ="/signup"
                  >SIGN UP</Button>} */}
              
          </div>
      </nav>
  </>
)
function navTo(val){
    navigate(val)
}
}

export default Navbar