import React from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import pic from "../Images/DCD.jpg"
import pic1 from "../Images/BRIGHT.jpg"
import pic2 from "../Images/ERNEST.jpg"
import pic3 from "../Images/FRANCIS.jpg"
import pic4 from "../Images/Derrick.jpg"
import pic5 from "../Images/kwesi.jpg"
import pic6 from "../Images/chris.jpg"
import pic7 from "../Images/SLY.JPG"
import pic8 from "../Images/swerwaa1.jpg"

export default function Central_Administration(){
    return(
        <>
            <Banner/>
            <Navbar/>
            <div className="container" style={{background:"white"}}>
            <div style={{ display:"flex",  marginTop:"50px", justifyContent:'center', color:"#008069", alignSelf:'center', backgroundColor:"#fff"}}>
            <h1 style={{textAlign:"center", color:"#008069", backgroundColor:"#fff"}}>CENTRAL ADMINISTRATION</h1>
            </div>

            <div >
            <img src={pic} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px",display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto"}} />
            <h3 style={{textAlign:"center", color:"#008069"}}>KANYEBUI TIMOTHY FORDJOUR</h3>
            <h4 style={{textAlign:"center"}}><b>DISTRICT CO-ORDINATINNG DIRECTOR</b></h4>
            <div style={{marginLeft:"50px", fontSize:"20px", marginRight:"50px", marginBottom:"50px"}}>
            <p>Central Administration is headed by the District Coordinating Director (DCD) and is responsible for ensuring the implementation of governmental policies, projects and programmes at the MMDAs level.</p>
            <p>The department guides policy formulation, planning and decision-making at the Assembly.</p>
            <p>As part of its responsibilities, Central Administration acts as a liaison between Heads of other governmental and non-governmental agencies and the District Chief Executive (DCE), it manages financial and other resources of the Assembly in consultation with the District Chief Executive (DCE)</p>
            <p>The Head of the Department serves as a Secretary to the Assembly and chairs the District Planning and Coordinating Unit (DPCU) and acts as an advisor to the District Chief Executive (DCE). The Department also ensures that the capabilities, skills and knowledge of staff are well developed.</p>
            </div>
            <p style={{backgroundColor:"#008069", height:"5px", width:"1310px"}} > </p>
            </div>

            <div style={{backgroundColor:"#e4f3e1", marginBottom:"20px", marginBottom:"50px"}}>
            <h3 style={{textAlign:"center", color:"#008069"}}>UNITS UNDER THE CENTRAL ADMINISTRATION</h3>
            
                <h2 style={{textAlign:"center"}}>BUDGET UNIT </h2>
                <div >
            <img src={pic2} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto"}} />
            <h3 style={{textAlign:"center", color:"#008069"}}>ERNEST DABOH</h3>
            <h4 style={{textAlign:"center"}}><b>UNIT HEAD</b></h4>
            </div>
            <p style={{backgroundColor:"#008069", height:"5px", width:" 1310px"}} > </p>
            </div>

            <div style={{backgroundColor:"#FFF", marginBottom:"50px", marginBottom:"0px"}}>
                <h2 style={{textAlign:"center"}}>INTERNAL AUDIT UNIT </h2>
                <div >
            <img src={pic5} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>ABDUL RAHMAN</h3>
            <h4 style={{textAlign:"center"}}><b>UNIT HEAD</b></h4>
            <p>The unit performs the following duties;</p>
            <li>Review and Update audit programs.</li>
            <li>Provides technical leadership in setting-up of a functional audit report implementation committee(AIRC).</li>
            <li>Reviews and ensure the execution of annual risk based audit plan</li>
            <li>Provides technical input for the drafting of proposals for the update of proceduers and guidlines for internal audit funtion in the  agency</li>
            <p style={{backgroundColor:"#008069", height:"5px", width:" 1310px"}} > </p>
            </div>
            </div>

            <div style={{backgroundColor:"#e4f3e1", marginBottom:"50px"}}>
                <h2 style={{textAlign:"center"}}>MIS UNIT </h2>
            <div >
            <img src={pic1} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>BRIGHT OPPONG GYAMFI</h3>
            <h4 style={{textAlign:"center"}}><b>UNIT HEAD</b></h4>
            <div style={{marginLeft:"100px", fontSize:"20px", marginRight:"50px", marginBottom:"50px"}}>
            <p>The unit provides leadership in the development and the application of IT/IM standards and quality assurance system in relation to the implementation, development and the deployment of IT/IM systems and resources.</p>
            <p>It also advises on the provision and maintenance of computers and other office accessories. The unit also contributes to addressing IT/IM problems and issues and provides leadership in the design and implementation of security measures to protect the IT/IM installations, infrastructure and systems.</p>
            </div>
            <p style={{backgroundColor:"#008069", height:"5px", width:" 131px"}} > </p>
            </div>
            </div>
            <div style={{backgroundColor:"#fff"}}>
                <h2 style={{textAlign:"center"}}>PLANNING UNIT </h2>
            <div style={{marginBottom:"50px"}}> 
            <img src={pic4} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto"}} />
            <h3 style={{textAlign:"center", color:"#008069"}}>DERRICK KOFI AMPAW</h3>
            <h4 style={{textAlign:"center"}}><b>UNIT HEAD</b></h4>
            <div style={{marginLeft:"100px", fontSize:"20px", marginRight:"50px", marginBottom:"100px"}}>
            <p>The District Planning & Coordinating Unit is responsible for providing leadership in the planning, implementation and evaluation of development projects and programmes in the Assembly.</p>
            <p>The Unit provides lead support in the preparation of Annual Reports, Development Action Plans and Medium Term Development Plans. It also monitors and reports on all development projects that takes place within the District.</p>
            <p>The Unit liaises with stakeholders to collect inputs necessary to aid in the formulation of public policies and programmes and also plays the role of ensuring that the capabilities, skills and knowledge of staff are well developed to ensure their effectiveness and efficiency.</p>
            <p>Assess </p>
            </div>
            </div>
            <p style={{backgroundColor:"#008069", height:"5px", width:" 1310px"}} > </p>
            </div>
            <div style={{backgroundColor:"#e4f3e1", marginBottom:"50px"}}>
                <h2 style={{textAlign:"center"}}>PROCUREMENT UNIT </h2>
                <div >
            <img src={pic3} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>FRANCIS OSEI</h3>
            <h4 style={{textAlign:"center"}}><b>UNIT HEAD</b></h4>
            <div style={{marginLeft:"100px", fontSize:"20px", marginRight:"50px", marginBottom:"50px"}}>
            <p>The unit provides strategic direction for the achievement of the overall objective of the procurement function in the Service.</p>
            <p>The unit plans and coordinates activities related to procurement, manages the development and implementation of the procurement plan, provides inputs for the preparation of the annual budget and liaises with service providers and other stakeholders to undertake procurement activities.</p>
            <p>It is also the responsibility of the Unit to ensure that the procurement activities are in harmony with the Public Procurement Act, 2003 (Act 663), supervise stores management and assets disposal as well as the Preparation and submission of Annual and Periodic Reports to the Public Procurement Authority. </p>
            </div>
            <p style={{backgroundColor:"#008069", height:"5px", width:"1310px"}} > </p>
            </div>
            </div>
            
            <div style={{backgroundColor:"#fff", marginBottom:"50px"}}>
                <h2 style={{textAlign:"center"}}>ENVIRONMENTAL HEALTH AND SANITATION UNIT </h2>
                <div style={{marginLeft:"70px", marginRight:"70px", fontSize:"20px"}}>
            <div >
            <img src={pic8} style={{position:'center', width:"400px", height:"400px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto" }} />
            <h3 style={{textAlign:"center", color:"#008069"}}>MRS. ALICE SERWAA BONSU</h3>
            <h4 style={{textAlign:"center"}}><b>UNIT HEAD</b></h4>
            <div style={{fontSize:"20px", marginBottom:"50px"}}>
            <p>The Environmental & Sanitation Unit is responsible for the following;</p>
            <ol style={{fontSize:"20px", marginBottom:"50px"}}>
                <li>Initiate Environmental Health activities, including clean-up campaigns and epidemic control</li>
                <li>Inspection, Monitoring, supervision and evaluation of Environmental Health projects.</li>
                <li>Inspect large-scale industrial establishments to ensure compliance with Environmental Health standards</li>
                <li>Support public education and prosecute sanitation offenders</li>
                <li>Education and Outreach.</li>
                <li>Policy development and review.</li>
                <li>Communication and collaboration.</li>
                <li>Support collection and analysis of  data for Management to make an inform decision.</li>
                <li>Monitor the hygenic state of facilities.</li>
            </ol>	
            </div>
            </div>
            </div>
            </div>    

            <p style={{backgroundColor:"#008069", height:"5px", width:" 1310px"}} > </p>
            <div style={{backgroundColor:"#e4f3e1", marginBottom:"50px"}}>
            <h2 style={{textAlign:"center"}}>REVENUE UNIT </h2>
            <div >
            <img src={pic7} style={{position:'center', width:"300px", height:"300px", borderRadius:"10px", display:"block", justifyContent:"center", alignItems:"center", borderRadius:"10px", border:"3px solid #008069", margin: "auto"}} />
            <h3 style={{textAlign:"center", color:"#008069"}}>MR bENJAMIN ASIEDU</h3>
            <p style={{textAlign:"center"}}><b>UNIT HEAD</b></p>
            </div>
            <p style={{backgroundColor:"#008069", height:"5px", width:" 1310px"}} > </p>
            </div>
            </div>
         <Footer/>
    
        </>
    )
}
