import React, { useEffect, useState } from "react"
import Banner from "../Resources/Banner"
import Footer from "../Resources/Footer"
import Navbar from "../Resources/Navbar"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, child, get, query, limitToLast } from "firebase/database";
import { Card, CardContent } from "@material-ui/core";
import noPic from '../Images/empty.png'
import firbase from '../Co/firease'
import { useNavigate } from "react-router-dom";
// import fireDB from "firebase/compact/app"

var uniVal=[]

export default function News(props){
  const [mRecs, setRecs] = useState(<div></div>)
  var recs =<div></div>  
  useEffect(() => {
    setRecs(<div>Searching</div>)
     getNews()
    }, [])

    const [tImage, setTimage] = useState("")
    
    async function getNews(){
const auth=getAuth() 
signInWithEmailAndPassword(auth, "amansiewestda@gmail.com", "misunit2021")
                .then((userCredential) => {
                    // Signed in 
                  
                    
                    
                    // ...
                    const dbRef = (getDatabase());
                    var trh=[]
                    var fQuery = get(query(ref(dbRef, `News/`)))
                    if(props.filter === "1"){
                       fQuery = get(query(ref(dbRef, `News/`),limitToLast(5)))
                    }
                    fQuery.then((snapshot) => {
                      if (snapshot.exists()) {
                        trh=[]
            var res={}
            snapshot.forEach((child) => {
                var name=""
                console.log(JSON.stringify(snapshot)+"mmmmmmm")
            res = {...res, Text : child.val().Text,
                Image : child.val().Image,
                Id : child.val().Id, 
                Topic : child.val().Topic,
                Author:child.val().Author,
                Datecreated:child.val().DateCreated,
              
              }
              if(res!==null){
                //console.log("bb", JSON.stringify(res))
                trh.push(res)
                }
            })
            trh.sort((a, b) => {
              const dateA = parseDate(a.Datecreated);
              const dateB = parseDate(b.Datecreated);
              return dateB - dateA; // Sort in descending order
            });
            
            function parseDate(dateString) {
              const parts = dateString.split('/');
              const day = parseInt(parts[0], 10);
              const month = parseInt(parts[1], 10);
              const year = parseInt(parts[2], 10);
              return new Date(year, month - 1, day).getTime();
            }
            //setdata(trh)
          
            uniVal = trh
           setNews("")
          
                      } else {
                        console.log("No data available");
                      }
                    }).catch((error) => {
                      console.error(error);
                    });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage)
                });         
}
let navigate = useNavigate()
function navTo(val){
  navigate(val)
}
function setNews(filter){
    recs = uniVal.map((val, key)=>{
     const topic = val.Topic.toUpperCase()
     var content = val.Text
     if(content.length>200){
       console.log("Helo")
       content = <p>{content.substring(0, 200)} <i  style={{fontWeight:'normal',color:'gray'}}>.......Tap for more</i></p>
     }
       return(
           <div onClick={()=>{
            localStorage.setItem("news_topic", val.Topic)
            localStorage.setItem("news_text", val.Text)
            localStorage.setItem("news_image", val.Image)
            navTo("/news_selected")
             
           }}  style={{width:'90%', display:'flex', margin:'20px',  justifyContent:'center', alignItems:'center'}}>
           
           <div style={{display:'flex', width:'100%',  justifyContent:'center', alignItems:'center'}} >
    

                <div style={{display:'flex', flexDirection:'row', marginBottom:"10px", width:'100%', justifyContent:'center', alignItems:'center'}}>
                    <div style={{flex:1}} >
                   
                      < img src={val.Image==="No Image"?noPic:val.Image} placeholder={noPic}
                    style = {{width: "40%",height:"30%", marginLeft: '30%', marginRight:'30%'
                      }} />
                    </div>
                    
                    <div style={{flex:2, display:'flex', flexDirection:'column'}}>
                       
                    <label style={{ fontSize:20, color:"#008069", display:'flex', fontWeight:'bold', textDecoration:'underline', textAlign:'center'}} >{topic}</label>

                    <label style={{ fontSize:18}} >{content}</label>
                    <div style={{display:'flex'}}>
                      <i style={{flex:1, color:"#008069", fontWeight:"bold"}}>Author : {val.Author}</i>
                      <i style={{flex:1, textAlign:'end', color:"#008069", fontWeight:"bold"}}>Posted on :{val.Datecreated}</i>
                    </div>
                   
                    </div>
                    
                    <div style={{flex:1}}></div>
                    
            
            </div>
           

            

           </div>

           
        
           </div>

        )
    })
    

    setRecs(recs)
}

return(
    <>
     <div style={{backgroundColor:"#fff"}}>
    {props.hide==="true"?<div></div>:
        <div><Banner/>
        <Navbar/> </div>
        }
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{textAlign: 'center'}}>
                <h1 style={{color:"#008069" }}>NEWS</h1>
                <div>
                    {mRecs}
                    {mRecs!=="" && props.filter==="1"?<a href="/news" style={{display:'flex', justifyContent:'center', alignItems:'center'}}><i style={{textAlign:'center', width:"100vw",  marginTop:"10px"}}>Click here for more</i></a>:null}
                </div>
            </div>
        </div>
        {props.hide==="true"?<div></div>:
            <><Footer/></>
        }     
   </div>
    </>
)
   /*} return(
        <>
         <div style={{backgroundColor:"#fff"}}>
        {props.hide==="true"?<div></div>:
            <div><Banner/>
            <Navbar/> </div>
            }
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    // your content here
            <h1 style={{textAlign:"center", color:"#008069" }}>NEWS</h1>
        <div>
        {mRecs}
        {mRecs!=="" && props.filter==="1"?<a href="/news" style={{display:'flex', justifyContent:'center', alignItems:'center'}}><i style={{textAlign:'center', width:"100vw",  marginTop:"10px"}}>Click here for more</i></a>:null}
        </div>
        
        {props.hide==="true"?<div></div>:

        <Footer/>
  }     
       </div>
       </div>
        </>
  )
//   function getNews(){
//         fireDB.dataBase
//   }
}
*/}